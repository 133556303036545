/* FAQs CTA Form Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
import slick from 'slick-carousel';

$(document).ready(function() {
	if ($(".faqs-cta-form-block .slick-faqs").length && $(".slick-faqs .faqs-slide").length > 5) {

		const formCTABackgroundTween = TweenMax.fromTo(".faqs-cta-form-wrapper .cta-background", 1, { y: '-25%' }, { y: '20%' });
		const formCTABackgroundController = new ScrollMagic.Controller();
		const formCTABackgroundScene = new ScrollMagic.Scene({
			triggerElement: ".faqs-cta-form-wrapper .cta-background",
			triggerHook: "onEnter",
			duration: "300%"
		}).setTween(formCTABackgroundTween);
		formCTABackgroundScene.addTo(formCTABackgroundController);

		let faqs_slicked = false;

		$(window).on('load resize rotate', function() {
			if ($(window).width() >= 992) {
				let maxHeight = 0;
				let match;
				match = $('.faqs-accordion .faqs-slide .card-body');
				match.each(function() {
					$(this).css({ 'opacity' : '0', 'visibility' : 'hidden', 'display' : 'block' });
					if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
					$(this).css({ 'display' : '', 'visibility' : '', 'opacity' : '' });
				});
				maxHeight = maxHeight + 250;
				$('.faqs-accordion').css('min-height', maxHeight + "px");

				if (faqs_slicked == false) {
					faqs_slicked = true;
					$(".slick-faqs").on('init', function() {
					}).slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						autoplay: false,
						autoplaySpeed: 5000,
						speed: 1000,
						mobileFirst: false,
						pauseOnDotsHover: false,
						draggable: false,
						pauseOnFocus: false,
						pauseOnHover: false,
						arrows: true,
						dots: false,
						focusOnSelect: false,
						fade: false,
						adaptiveHeight: true,
						centerMode: true,
						centerPadding: 'inherit',
						appendArrows: '#slick-faqs-navigation',
						prevArrow: '<div class="slick-navigation prev-arrow"></div>',
						nextArrow: '<div class="slick-navigation next-arrow"><span><i class="far fa-arrow-left"></i></span></div>',
						rows: 5,
						responsive: [
							{
								breakpoint: 992,
								settings: "unslick"
							},
						]
					}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
						$('.slick-slide[data-slick-index="' + (nextSlide) + '"] >:first-child .faqs-slide .card .card-header').trigger('click');
					});
				}
			}
			else {
				$('.faqs-accordion').css('min-height', "");
				if (faqs_slicked == true) {
					faqs_slicked = false;
					$(".slick-faqs-navigation").empty();
				}

			}
		})




	}
});
