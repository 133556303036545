/* Tabbed Content Block JS File */
import tab from 'bootstrap';
$(document).ready(function() {
	if ($('.tabbed-content-wrapper').length > 0) {

		if ($(".tabbed-content-wrapper").length > 0) {
			$('.tabbed-content-wrapper .accordion').collapse().on('hide.bs.collapse hidden.bs.collapse show.bs.collapse shown.bs.collapse', function(e) {
				TweenMax.to(window, 0.5, { scrollTo: { y: scrollSnap.activeSnap, autoKill: false}, ease: Power4.easeOut });
			});
		}
		$('[data-toggle="collapse"]').on('click', function(e) {
			const isOpen = $($(this).attr('href')).hasClass('show');
			if (isOpen) {
				e.preventDefault();
				e.stopPropagation();
			}
		});
		if ($(".card-header").length) {
			const cardheader = $(".card-header");
			cardheader.on('click', function() {
				$(".tabbed-content-wrapper .section-body .section-accordion .section-backgrounds-wrapper .section-background[data-id=" + $(this).attr('data-id') + "]").addClass('active');
				$(".tabbed-content-wrapper .section-body .section-accordion .section-backgrounds-wrapper .section-background[data-id!=" + $(this).attr('data-id') + "]").removeClass('active');
			});
		}


		$('.tabbed-content-tab').tab();
		$('.nav-tabs a').on('click', function (e) {
			e.preventDefault()
			$(this).tab('show')
		});
		if ($(".nav-link").length) {
			const navlink = $(".nav-link");
			navlink.on('click', function() {
				$(".tabbed-content-wrapper .section-body .section-tabs .tabbed-content-tab .nav .section-images-wrapper .section-image[data-id=" + $(this).attr('data-id') + "]").addClass('active');
				$(".tabbed-content-wrapper .section-body .section-tabs .tabbed-content-tab .nav .section-images-wrapper .section-image[data-id!=" + $(this).attr('data-id') + "]").removeClass('active');
			});
		}

	}

});

